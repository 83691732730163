<style scoped>
.not_use { background-color:#f4f4f4; color:#777777; }
</style>

<template>
    <v-card outlined>
        <v-card-title class="pl-7 block-title">오시는 길</v-card-title>
        <v-card-text class="pl-0 pr-0 pb-0">
            <v-simple-table :class="{'not_use': wvUseYn == 'N'}">
                <tr>
                    <td class="pt-5 pl-7 pr-7 pb-5 block-line-head block-br block-bb" style="width:200px;">주소</td>
                    <td class="pt-5 pl-7 pr-7 pb-5 block-bb">
                        <v-text-field outlined hide-details v-model="companyAddr.addr1"></v-text-field>                    
                    </td>
                    <td style="width:150px;" class="block-bb">
                        <v-btn color="#4E87DD" large style="color:#FFFFFF; font-size:18px;" @click="openAddrSearch">주소 검색</v-btn>
                    </td>
                </tr>
                <tr>
                    <td class="pt-5 pl-7 pr-7 pb-5 block-line-head block-br" rowspan="2" style="width:200px;">상세주소</td>
                    <td class="pt-5 pl-7 pr-7 pb-2" colspan="2">
                        <v-text-field outlined hide-details v-model="companyAddr.addr2"></v-text-field>
                    </td>
                </tr>                  
                <tr>
                    <td class="pt-2 pl-7 pr-7 pb-5" colspan="2">
                        <v-textarea outlined hide-details placeholder="추가 설명을 입력해주세요." v-model="companyAddr.addr_desc">
                        </v-textarea>   
                    </td>
                </tr>
            </v-simple-table>
        </v-card-text>
        <v-dialog v-model="modal" persistent max-width="900" @keydown.esc="closeDialog()">
            <v-flex v-if="dialogMode === 'addr'">
                <addr-search-popup title="주소검색" v-on:submit="applyAddr" v-on:cancel="closeDialog" ></addr-search-popup>
            </v-flex>
        </v-dialog>
    </v-card>  
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'svc_wv_company_addr',
    components: {
        'addrSearchPopup': () => import('@/components/svc/wv/popup_addr_search'),      
    },
    data: () => ({
        modal: false,
        dialogMode: 'none'
    }),
    created () { 
    },  
    mounted () { 
    },
    computed: {
        ...mapState('svcWv', {
            wvUseYn: state =>state.wvUseYn,
            companyAddr: state => state.companyAddr,
        }),    
    },  
    methods: { 
        openAddrSearch: function() { 
            this.modal = true
            this.dialogMode = 'addr'
        },
        closeDialog() { 
            this.modal = false
            this.dialogMode = 'NONE'
        }, 
        applyAddr(addr) { 
            if(addr) {
                this.$set(this.companyAddr, 'post_no', addr.post_no)
                this.$set(this.companyAddr, 'addr1', addr.addr1 + ' ' + addr.bd_nm)
                this.$set(this.companyAddr, 'addr2', addr.addr2)
                this.$set(this.companyAddr, 'google_map_lng', '')
                this.$set(this.companyAddr, 'google_map_lat', '')
            }

            this.closeDialog()
        },         
    }
}</script>